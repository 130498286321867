import React from 'react';

import { Section } from 'components/Section';

import style from './style.module.scss';

import { Separator } from 'components/Separator';
import IndyLink from 'components/IndyLink';

export function AnyQuestions(){
    return (<>
        <Separator margin="TOP" />
        <Section>
            <p className={style.text}>Do you have any questions? If so, <IndyLink adr="/about/#ContactUs">get in contact</IndyLink>.</p>
        </Section>
    </>)
}