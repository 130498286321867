import React from 'react';

import PageWrap from 'components/PageWrap';

import style from './style.module.scss';

import { Separator } from 'components/Separator';

export function ContentPage({Title, path, children}){
    return (
        <PageWrap
            title={Title + " - Mashoom"}
            path={path}
        >
            <div id={style.container}>
                <h1>{Title}</h1>
                <Separator id={style.CPTitleSep} />
                <div>
                    {children}
                </div>
            </div>
        </PageWrap>
    );
}